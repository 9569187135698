import { IComponentController } from '@wix/editor-elements-types';
import { IWPhotoControllerActions, WPhotoStateRefs } from '../WPhoto.types';

const compController: IComponentController<WPhotoStateRefs> = {
  mapStateToProps: ({ experiments }) => ({
    lazyLoadImgExperimentOpen:
      !!experiments?.['specs.thunderbolt.lazyLoadImages'],
  }),
  mapActionsToProps: ({ updateProps }): IWPhotoControllerActions => ({
    onSizeChange: (width, height) => {
      updateProps({ width, height });
    },
  }),
};

export default compController;
